import React from 'react';
import {Link} from 'react-router-dom';


export default function Imprint () {

    return (
        <section className='module light'>
            <h4>Embedded Systems Engineering | Daniel Tzschentke</h4>
            <br />
            <p>
            Goslarer Platz 5 <br />
            10589 Berlin<br />
            Deutschland<br />
            <br />
            Telefon: +49 157 3721 8611<br />
            E-Mail: hi@ese-labs.de<br />
            <br />

            © ESE Labs 2024
            </p>
            <br />
            <Link to = '/' className='h4' alt= 'home'>Home</Link>
        </section>
    )
} 