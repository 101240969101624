import React from 'react';
import Tile from '../components/Tile';
import tileData from '../tileData';
import FAB from '../components/FAB';


export default function Showcase () {

    const TileGrid = tileData.map (item =>
        {
            return (
                <Tile 
                    key= {item.id}
                    title= {item.title}
                    subtitle= {item.subtitle}
                    text= {item.text}
                    image= {item.imageUrl}
                    id = {item.sectionTitle}
                />
            )
        })

    return (
        <section className = 'showcase light' id= 'intro'>
                {TileGrid}
         </section>
    )
} 
