import React from 'react';

export default function CaseItem ({client, image, project, work, url}) {
    return (
        <div className='case-item'>
            {/* <img className= 'case-image' src= {image} alt= 'case study'/> */}
            <div className='case-text'>
            <a href= {url} target= '_blank' alt= 'customer link'><h3>{client}</h3></a>
                
                <div>
                    <p className='mono'>{project}</p>
                    <p className='mono emphasis'>{work}</p>
                </div>
            </div>         
            
        </div>
    )
}