import React from 'react';

import Showcase from '../modules/Showcase';
import Intro from '../modules/Intro';
import Cases from '../modules/Cases';
import Services from '../modules/Services';
import Bio from '../modules/Bio';
import Footer from '../modules/Footer';
import FAB from '../components/FAB';



export default function Home () {
    return (
        <>
            <Showcase />
            <FAB />
            <Intro />
            <Services />
            <Cases />
            <Bio />
            <Footer />
        </>
    )
}