import React from 'react';
import {Link} from 'react-router-dom';

export default function NoPage () {
    return (
        <section className='module light centred'>
            <div>
                <h2>Sorry, nothing here.</h2>
                <p>Error 404</p>
            </div>
            <div>
                <p>Return to page by clicking the link below </p>
                <Link to = '/' alt= 'link to ese-labs.de homepage'>ese-labs.de</Link>
            </div>
        </section>
    )
}