import React from 'react';
import BioItem from '../components/BioItem';
import EduItem from '../components/EduItem';


export default function Bio () {
    return (
        <section className='module light one-two'>
            {/* add links to the name of the company */}
            
            <div className='bio-start'>
                <img src= './assets/bio/bio.png' className= 'bio-image' alt ='cv' />
                
                <caption className='muted'>Daniel Tzschentke<br />
                I love music, engineering and <a href= 'http://buggy-landwasser.de/' className= 'caption' target= '_blank' alt= 'buggy landwasser art'>art</a></caption>
            </div>

            <div className='bio-end'>
                
                    {/* <h4>Work</h4> */}
                        <div className='list'>
                            <BioItem
                                role = 'Lead Developer'
                                company = 'Nonlinear Labs'
                                url= 'https://www.nonlinear-labs.de/product/c15.html'
                                domain = 'Electronics and system software'
                                product = 'Development of C15, a keyboard for performing musicians with focus on Real-Time'
                            />
                            <BioItem
                                role = 'Development Engineer'
                                company = 'Senec'
                                url= 'https://senec.com/de'
                                domain = 'Hardware and Software'
                                product = 'Electronics development of an energy management system; hardware/software interfaces'
                            />
                            <BioItem
                                role = 'Senior Hardware Engineer'
                                company = 'NBT'
                                url= 'https://nextbigthing.ag/'
                                domain = 'Mechanics and Electronics'
                                product = 'Development of a general IoT-hardware platform'
                            />
                            <BioItem
                                role = 'Embedded Systems Engineer'
                                company = 'Soundbrenner'
                                url= 'https://www.soundbrenner.com/'
                                domain = 'Embedded Software'
                                product = 'Electronics review for series production of Core, a smart watch for musicians'
                            />
                            <BioItem
                                role = 'Senior Development Engineer'
                                company = 'PTScientists'
                                url= 'https://www.pts.space/'
                                domain = 'Electronics'
                                product = 'Bus-system development for moon lander'
                            />
                        </div>
                    <div className='bio-edu'>
                        <h3>Education</h3>
                            <div className= 'bio-list'>
                                <EduItem
                                    title = 'Electronics Engineering | Telecommunications'
                                    degree = 'Bachelor of Engineering'
                                />
                                <EduItem
                                    title = 'Human Machine Interaction'
                                    degree = 'Master of Arts'
                                />
                            </div>
                        </div>
                    </div>

                {/*
                <span className= 'bio-footer'>
                    <a className= 'contact' href= './assets/cv.pdf' download='Daniel_Tzschentke_CV' alt='cv'>download cv </a>
                    <a className= 'contact' href= 'mailto:dan@yoursite.com' alt='send an email to dan@ese-labs.de'>dan@ese-labs.de</a>
                </span>
                */}
    
        </section>
    )
} 