import React from 'react';

export default function Tile (props) {
    return (
        <div className= 'tile'>        
            { props.title  &&
            <div className= 'tileContent'>
                <div className= 'tileHeadline'>
                    <h5>{props.title}</h5>
                    <h5>{props.subtitle}</h5>
                </div>
                { props.text  &&
                <p className= 'caption tileText'>{props.text}</p>}
            </div>
            }

            <img src= {`./assets/tiles/${props.image}`} alt='section preview' className= 'tileImg'/>
        </div>
    )
}

