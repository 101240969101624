import React from 'react';
import {Link} from 'react-router-dom';

export default function Footer () {

    return (
        <footer>
            <div className='footer-content'>
                <div className='row'>
                    <h1 className='centred'>Product idea? <br /> Let's make it work!</h1>
                </div>
                <a href= 'mailto:dan@ese-labs.de'><h2>hi@ese-labs.de</h2></a>
            </div>

                <span className='footernotes'>
                <Link to = 'imprint' className='caption' alt= 'imprint'>Imprint</Link>
                <p className='caption'>Copyright © ESE Labs 2024</p>
                <a className='caption' href='https://byramona.io/' target = '_blank' rel=' noreferrer' alt= 'opens in new tab webpage of designer and coder'>Made with ♡ byRamona</a>
               
                </span>

         
        </footer>
    )
}