import React from 'react';

export default function ServiceItem ({title, item0, item1, item2, item3, item4, item5}) {
    return (
        
            <div className= 'service-item'>
                <h3>{title}</h3> 
                <ul>
                    <li className='subdued'>{item0}</li>
                    <li className='subdued'>{item1}</li>
                    <li className='subdued'>{item2}</li>
                    <li className='subdued'>{item3}</li>
                    <li className='subdued'>{item4}</li>
                    {item5 && 
                    <li className='subdued'>{item5}</li>
                    }
                </ul>
            </div>
    )
}


