import React from 'react';
import CaseItem from '../components/CaseItem';

export default function Cases () {
    return (
        <section className='module light stack'>
            <h2>Selected Clients</h2>
            {/* add links to companies */}
            <div className='list'>
                <CaseItem
                    image= './assets/case/case_00.jpg'
                    client= 'Esper Bionics'
                    url= 'https://esperbionics.com/'
                    project = 'myo sensor board'
                    work = 'embedded software and consulting'
                />
                <CaseItem
                    image= './assets/case/case_01.jpg'
                    client= 'Funke Gerber Labortechnik'
                    url= 'https://funke-gerber.de/home'
                    project = 'measurement equipment for milk and beer industries'
                    work = 'machine development'
                />
                <CaseItem
                    image= './assets/case/case_02.jpeg'
                    client= 'Nonlinear Labs'
                    url= 'https://www.nonlinear-labs.de/'
                    project = 'audio box electronics'
                    work = 'pcb development'
                />

                </div>
        </section>
    )
}