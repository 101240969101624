import React from 'react';
import ServiceItem from '../components/ServiceItem';

export default function Services () {
    return (
        <section className='module light stack'>
            <h2>Services</h2>

                <div className= 'services'>

{/* bullet points && sevices on top*/}

                <div className= 'two'>
                <ServiceItem 
                title= 'Development'
                item0= 'concept development'
                item1= 'project evaluation'
                item2= 'proof of concept/prototype'
                item3= 'idea to embedded system'
                item4= 'full development' 
                />
                
                <ServiceItem 
                title= 'Domains'
                item0= 'physics'
                item1= 'installation'
                item2= 'music electronics'
                item3= 'industrial'
                item4= 'consumer' 
                />

                </div>

                <div className= 'three '>

                <ServiceItem 
                title= 'Electronics'
                item0= 'schematics'
                item1= 'PCB'
                item2= 'manufacturer communications'
                item3= 'assembly'
                item4= 'testing' 
                />

                <ServiceItem
                title= 'Software'
                item0= 'embedded: C/C++'
                item1= 'GUI: python/C++'
                item2= 'architecture'
                item3= 'real time'
                item4= 'control' 
                item5= 'communication'
                />
                
                <ServiceItem
                title= 'Mechanics'
                item0= 'concepts development'
                item1= 'CAD: parts, housing'
                item2= 'PCB integration'
                item3= 'manufacturer communications'
                item4= '3D print, laser cutting, CNC' 
                item5= 'small series device/machine assembly'
                />

                </div>

            </div>

        </section>
    )
}