const tileData = [
    {
        id: '01',
        title: 'drum machine',
        subtitle: 'mosaik',
        imageUrl: '01.jpg'
    },

    {
        id: '02',
        title: 'system control',
        subtitle: '',
        imageUrl: '02.png'
    },

    {
        id: '03',
        title: 'medium handling',
        subtitle: '',
        imageUrl: '03.png'
    },

    {
        id: '04',
        title: 'freeze casting',
        imageUrl: '04.png',
        sectionTitle: 'contact',
    },

    {
        id: '05',
        imageUrl: '05.png',
    },

    {
        id: '06',
        title: 'music electronics prototype',
        imageUrl: '06.jpg'
    },

    {
        id: '07',
        title: 'midi cube',
        subtitle: 'an.schlag',
        imageUrl: '07.jpg'
    },
    {
        id: '08',
        title: 'C15 mainboard',
        subtitle: 'Nonlinear Labs',
        imageUrl: '08.jpg'
    },
    {
        id: '09',
        title: 'fan + LED technology',
        subtitle: 'Planto Systems',
        imageUrl: '09.jpg'
    },
]

export default tileData