import React from 'react';

export default function FAB () {

    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };
    
     return (
        <button onClick={goToTop} className='FAB'><img className='icon' src= './assets/icons/chevron-up.svg' alt= 'scroll to top' /></button>
     )
}
