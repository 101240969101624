import React from 'react';

export default function BioItem ({role, domain, company, product, url }) {
    return (
        <div className='bio-item'>
            <div className='bio-header'>
                <h3>{role}</h3>
                <a href = {url} target= '_blank' alt= 'client company page' className='h3'>
                    <h3>{company}</h3>
                </a>
            </div>
            <p className='bio-body'>
                { domain && <span className= 'h4'> {domain} | </span> }       
                {product}
            </p>
        </div>
    )
}