import React from 'react';

export default function Intro () {

    return (
        <section className= 'module'>
            <div className='intro'>
                <h2>ESE Labs is an Embedded Systems Engineering office based in Berlin. </h2>
                <h2>We offer end-to-end solutions, from research to production-ready prototypes. With over 10 years of engineering experience across various industries, we deliver practical  solutions to complex problems.</h2> 
                <a className= 'h2' href= 'mailto:dan@ese-labs.de' alt= 'contact dan@ese-labs.de'>hi@ese-labs.de</a>
            </div>
        </section>
    )
} 