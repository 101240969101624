import './styles.css';
import './typography.css';

import Home from './pages/Home';
import Imprint from './pages/Imprint';
import NoPage from './pages/NoPage';

import {BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import {useEffect} from 'react';


function App() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
      <div className="App">
        <Router>
              <Routes>
                <Route path = '/' element = {<Home />} />
                <Route path = '/imprint' element = {<Imprint />} />           
                <Route path = '*' element = {<NoPage />} />
            </Routes>
        </Router>

      </div>
  );
}

export default App;
